.events-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    padding: 20px;
    text-align: center;
    background-color: #712a00;
  }
  
  .events-container h1 {
    margin-top: 95px;
    margin-bottom: 20px;
  }
  
  .events-container p {
    padding: 0rem 2rem;
    font-size: 1.2rem;
    color: #fff;
  }
  
  .calendar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .react-calendar {
    width: 100%;
    max-width: 500px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    color: black;
  }
  
  .react-calendar button {
    color:black;
  }