.member-container {
    padding: 20px;
    text-align: center;
    color: #fff;
    background-color: #712a00;
  }
  
  .member-container h1 {
    padding-top: 57px;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .member-container p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .message-box {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .error {
    background-color: #f8d7da;
    color: #721c24;
  }

  
  form {
    display: inline-block;
    text-align: left;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  input, select, textarea {
    padding: 8px;
    margin-top: 5px;
    width: 100%;
    box-sizing: border-box;
  }
  
  button {
    background-color: #d69324;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #b5751a;
  }
  