.donations-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #712a00;
    min-height:90vh;
    margin-top: 76px;
  }
  
  .donations-container p {
    margin-bottom: 20px;
    color: #fff;
    font-size: 1.2rem;
  }