@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.navbar {
  background-color: #d69324; /* Dark background */
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px; /* Increased padding for a more formal look */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 1000; /* Ensures navbar stays on top */
  margin-right: 20px;
}

.navbar.hidden {
  transform: translateY(-100%); /* Move it out of view */
  opacity: 0; /* Make it invisible */
}

.navbar.visible {
  transform: translateY(0); /* Bring it back into view */
  opacity: 1; /* Make it visible */
}

.navbar-logo img {
  height: 60px; /* Adjust the height as needed */
  width: auto; /* Maintains the aspect ratio */
  max-width: 100%; /* Ensures the image is responsive */
}

.navbar-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  transition: max-height 0.3s ease-in-out, visibility 0.3s ease-in-out;
  justify-content: center; /* Center items when visible */
}

.navbar-item {
  padding: 14px 20px;
}

.navbar-item a {
  color: #000; /* Ensure good contrast */
  font-family: Montserrat, sans-serif; 
  font-size: 17px; /* Adjust the size as needed */
  text-align: center;
  text-decoration: none;
  display: block;
  padding: .3rem;
  border-radius: 4px; /* Rounded corners for a formal touch */
}

.navbar-item a:hover {
  color:#712a00; /* Dark text on hover */
}

.navbar-item {
  position: relative; /* Ensure the pseudo-element is positioned relative to the navbar-item */
}


.navbar-toggle {
  display: none;
  background-color: #d69324; /* Consistent background */
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjust as needed */
  height: 30px; /* Adjust as needed */
}

.line {
  width: 100%;
  height: 4px; /* Thickness of the lines */
  background: white; /* Color of the lines */
  margin: 2px 0;
  transition: all 0.3s ease; /* Smooth transition */
}

.line1.open {
  transform: rotate(45deg) translate(5px, 5px);
}

.line2.open {
  transform: rotate(-45deg) translate(5px, -5px);
}

.material-icons {
  font-size: 24px; /* Adjust the size as needed */
}

@media (max-width: 768px) {

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .navbar-wrapper {
    display: flex;
    justify-content: space-between; /* Position items on opposite ends */
    align-items: center; /* Center items vertically */
    width: 90%;
    margin-right: 3rem;
  }

  .navbar-toggle {
    display: flex; /* Ensure it's a flex container */
    flex-direction: column; /* Stack the lines vertically */
    justify-content: center; /* Center the lines vertically */
    align-items: center; /* Center the lines horizontally */
    width: 40px; /* Adjust as needed */
    height: 30px; /* Adjust as needed */
    background: none; /* No background */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  .line {
    width: 100%; /* Full width */
    height: 2px; /* Thickness of the lines */
    background: black; /* Color of the lines */
    margin: 10px 0; /* Space between the lines */
    transition: all 0.3s ease; /* Smooth transition */
  }
  
  /* Open state styles for transitions */
  .line1.open {
    transform: rotate(45deg) translate(3px, 3px);
  }
  
  .line2.open {
    transform: rotate(-43deg) translate(5px, -6px);
  }

  .navbar-list {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    background-color: #d69324;
    visibility: hidden;
    transition: max-height 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  .navbar-list.open {
    max-height: 500px; /* Adjust as needed for smooth dropdown */
    visibility: visible;
  }

  .navbar-item {
    padding: 10px 10px;
  }

  .navbar-toggle {
    display: block;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .navbar-list {
    flex-direction: column;
    align-items: center; /* Center items vertically */
    width: 90%;
    max-height: 0;
    overflow: hidden;
    background-color: #d69324;
    visibility: hidden;
    transition: max-height 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  .navbar-list.open {
    max-height: 500px; /* Adjust as needed for smooth dropdown */
    visibility: visible;
    justify-content: center; /* Center items when the menu is open */
  }
}

@media (min-width: 769px) {
  .navbar-list {
    flex-direction: row;
    align-items: center;
    max-height: none;
    visibility: visible; /* Always visible on larger screens */
  }

  .navbar-toggle {
    display: none;
  }
}