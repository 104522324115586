/* Team.css */

.team-container {
    background-color: #712a00;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    padding: 30px;
  }
  
  .team-image {
    width: 100%;
    max-width: 930px;
    height: auto;
    max-height: 600px;
    margin: 2rem;
  }

  .team-paragraph{
    padding: 0rem 2rem;
    font-size: 1.2rem;
    color: #fff;
  }
  
  .team-title {
    text-align: center;
    margin-top: 100px;
  }
  
  .team-paragraph {
    text-align: center;
    margin: 10px 0;
  }
  
  .team-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .team-list-item {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: 250px; /* Fixed width for all boxes */
    transition: transform 0.3s ease, background-color 0.3s ease;
    height: 250px; /* Fixed height for all boxes */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center content horizontally */
  }
  
  /* .team-list-item:hover {
    transform: scale(1.05);
    background-color: #e0e0e0;
    }
   */
  
  .job-description {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 8px;
    /* position: absolute; */
    top: 0;
    left: 0;
    /* width: 100%;
    height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1; 
    transition: opacity 0.3s ease;
  }

  .apply-button {
    background-color: #d69324;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .apply-button:hover {
    background-color: #b5751a;
  }

  