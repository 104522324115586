
.home {
  background-color: #3d0a09;
  color: #fff;
  overflow: hidden;

}

.caption h1{
  margin-top: 100px;
  padding: 1rem;
  text-align: center;
}

.home img {
  height: 100vh; /* Keep this for non-carousel images */
  width: 100vw;
  object-fit: cover;
  object-position: center;
}

/* Carousel images */
.carousel img {
  width: 100%; /* Full width of the carousel */
  height: 400px; /* Fixed height for all carousel images */
  object-fit: cover; /* Cover the entire area */
  object-position: center; /* Center the image */
}

/* .home:hover img {
  filter: grayscale(100%);
} */




h1 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-size: 3rem;
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 1.4rem;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
}

.team-content {
  background-color: #712a00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
}

.team-content h1 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-size: 3rem;
  color: #d69324;
  display: flex;
  justify-content: center;
  margin: 0.3rem;
}

.team-content button {
  background-color: #d69324;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.team-content a {
  color: rgb(14, 3, 3);
  text-decoration: none;
}


@media (max-width: 768px) {
  .caption h1{
    padding: 0.5rem;
    font-size: 2.6rem;
  }

 .home img {
    height: 100vh; /* Make sure the image height is 100% of the viewport height */
    width: 100vw;
    object-fit: cover;
    object-position: center;
  }
  
  .carousel img {
    height: 250px; /* Adjust height for smaller screens */
  }

  h1 {
    font-size: 2.6rem; /* Adjust h1 font size */
  }
  
  .home-content {
    padding: 50px;
    font-size: 1.5rem;
  }
}


/*
@media (max-width: 480px) {
  .carousel .slide {
    height: 200px; 
  }

  .carousel .slide img {
    object-fit: cover; 
    object-position: center top; 
  }
  
  .carousel-caption {
    font-size: 1rem; 
    padding: 5px;
    width: 100%;
  }
  
  h1 {
    font-size: 1.5rem; 
  }
  
  .home-content {
    padding: 20px;
    font-size: 0.9rem;
  }
}
*/