.opportunities-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #712a00;
  min-height:90vh;
}

.opportunities-container h1 {
  margin-top: 95px;
}
.opportunities-container p {
  margin-bottom: 20px;
  color: #fff;
  font-size: 1.2rem;
}