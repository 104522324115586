.footer {
    background-color: #3d0a09;
    color: white;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    max-width: 1200px;
    width: 100%;
    align-items: center;
  }
  
  .footer-section {
    flex: 1 1 200px;
  }
  
  .logo-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-logo {
    height: 60px;
    margin-bottom: 10px;
  }
  
  .club-name {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .social-media-section {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .social-icon {
    font-size: 1.5rem;
    color: white;
  }
  
  .contact-section p,
  .links-section a {
    margin: 10px 0;
    padding: 0.4rem;
  }
  
  .footer-link {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-link:hover {
    color: #d69324;
  }
  

  .footer-link, .footer-mailto {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-link:hover, .footer-mailto:hover {
    color: #d69324;
  }